@import "../entrypoint_init";

$theme-jazz-primary: hsl(223deg 77% 63%);
$theme-jazz-primary-filter: brightness(0%) saturate(100%) invert(52%) sepia(17%)
  saturate(4564%) hue-rotate(202deg) brightness(92%) contrast(100%);
$theme-jazz-secondary: hsl(200deg 77% 64%);
$theme-jazz-secondary-filter: brightness(0%) saturate(100%) invert(68%) sepia(58%)
  saturate(1052%) hue-rotate(171deg) brightness(99%) contrast(85%);

.theme {
  &.theme-jazz {
    @include theme-variables(
      $theme-jazz-primary,
      $theme-jazz-secondary,
      $theme-jazz-primary-filter,
      $theme-jazz-secondary-filter
    );
    @include theme($theme-jazz-primary, $theme-jazz-secondary);
  }
}

.memorial-theme-option {
  &[data-theme-name="theme-jazz"] {
    @include theme-preview($theme-jazz-primary, $theme-jazz-secondary);
  }
}
